
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">

			<v-flex xs12 lg12 xl12 md12 sm12 class="mb-5 ">
				<h1 class="text-center ">Dollar Price : {{store_information_list[0].dollar_price}}</h1>
			</v-flex>
			<form @submit.prevent="addStoreInformation" autocomplete="off">
				<v-layout row wrap>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="store_information.dollar_price" type="text" :label="$store.getters.language.data.store_information.dollar_price" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="store_information.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.store_information.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="store_id">
								<!-- <template v-slot:[`item.store_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/store_information-list/'+item.store_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectStoreInformation(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template> -->
								<!-- show store_date -->
								<template v-slot:[`item.store_date`]="{ item }">
									<div>
										{{ new Date(item.store_date).toISOString().split('T')[0]}} {{ new Date(item.store_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteStoreInformationList">{{$store.getters.language.data.store_information.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.store_information.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.store_information.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteStoreInformation(selected_store_information.store_id)">
						{{$store.getters.language.data.store_information.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/store_information.request.js'
	export default {
		data() {
			return {
				store_information: {
					user_id: this.$store.getters.user.user_id,
				},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_store_information: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.store_information.dollar_price,
						align: 'start',
						sortable: true,
						value: 'dollar_price',
					},
					{
						text: this.$store.getters.language.data.store_information.store_date,
						align: 'start',
						sortable: true,
						value: 'store_date',
					},
					{
						text: this.$store.getters.language.data.users.user_username,
						align: 'start',
						sortable: true,
						value: 'user_username',
					},
				],
			}
		},
		computed: {
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			},
			store_information_list() {
				return this.$store.getters.store_information_list
			}
		},
		mounted() {
			this.readStoreInformation();
		},
		methods: {
			addStoreInformation() {
				this.loading_btn = true
				this.store_information.user_id = this.user.user_id
				requests.createStoreInformation(this.store_information).then(r => {

					if (r.status == 200) {

						this.store_information = {}
						this.store_information.user_id = this.user.user_id
						this.rows.unshift(
							r.data.new_data
						)
						this.$store.getters.store_information_list.unshift(r.data.new_data)
						this.snackbar = {
							value: true,
							text: 'StoreInformation Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add StoreInformation',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteStoreInformation(store_id) {
				requests.deleteStoreInformation(store_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.store_id != store_id
						})
						this.snackbar = {
							value: true,
							text: 'StoreInformation Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteStoreInformationList() {
				let ids = this.selected_rows.map(m => m.store_id)
				requests.deleteStoreInformationList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.store_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' StoreInformation Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readStoreInformation() {
				this.loading = true
				requests.getAllStoreInformation().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read StoreInformation',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read StoreInformation',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectStoreInformation(i) {
				this.selected_store_information = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    